import type { AWSError } from 'aws-sdk'
import type {
  AdminSetUserPasswordRequest,
  CodeDeliveryDetailsType,
  ResendConfirmationCodeResponse as NewConfirmationCodeResponse,
  SignUpResponse,
} from 'aws-sdk/clients/cognitoidentityserviceprovider'

export { AWSError }

export interface LoginParams {
  username: string
  password: string
}

export interface RegisterParams extends LoginParams {
  email: string
  username: string
}

export interface RegisterParamsResponse extends SignUpResponse {}

export interface ConfirmationCodeParams extends Pick<RegisterParams, 'username'> {
  code: string
}

export interface ResendConfirmationCodeResponse extends NewConfirmationCodeResponse {}

export interface ConfirmationCodeResponse {
  ok?: true
}

export enum CognitoUserGroup {
  MantisTeam = 'MantisTeam',
  PageUrl = 'page:', // README: Dynamic groups by url => page:propaganda-detector
}

export interface UserAttributes {
  sub: string
  email: string
  email_verified: boolean
}

/** @deprecated */
export interface User extends UserAttributes {
  id: UserAttributes['sub']
  username: string
}

/** @deprecated */
export interface CurrentUser extends User {
  groups: CognitoUserGroup[]
}

export interface ResetPasswordParams extends Pick<RegisterParams, 'username'> {}

export interface ResetPasswordResponse extends CodeDeliveryDetailsType {}

export interface ChangePasswordParams extends Pick<ConfirmationCodeParams, 'code'>, LoginParams {}

export interface ForceChangePasswordParams {
  username: AdminSetUserPasswordRequest['Username']
  password: AdminSetUserPasswordRequest['Password']
}
