import React from 'react'
import classnames from 'classnames'
import { Dropdown as ADropdown, DropdownProps as ADropdownProps, MenuProps, Space } from 'antd'
import styles from './Dropdown.module.scss'

export interface DropdownProps
  extends Pick<ADropdownProps, 'overlayClassName' | 'placement' | 'onOpenChange' | 'destroyPopupOnHide'> {
  className?: string
  menuClassName?: string
  items: MenuProps['items']
  trigger?: 'click' | 'hover'
  selectable?: MenuProps['selectable']
  defaultSelectedKeys?: MenuProps['defaultSelectedKeys']
  onSelect?: MenuProps['onSelect']
}

export const Dropdown: React.FC<React.PropsWithChildren<DropdownProps>> = ({
  className,
  menuClassName,
  overlayClassName,
  items = [],
  trigger,
  selectable,
  defaultSelectedKeys,
  destroyPopupOnHide,
  placement,
  children,
  onOpenChange,
  onSelect,
}) => (
  <ADropdown
    className={classnames(styles.container, className)}
    overlayClassName={overlayClassName}
    trigger={trigger ? [trigger] : undefined}
    placement={placement}
    onOpenChange={onOpenChange}
    destroyPopupOnHide={destroyPopupOnHide}
    menu={{
      items: items.map<any>((item) => ({ ...item, className: classnames(styles.menuItem, item?.className) })),
      selectable,
      defaultSelectedKeys,
      rootClassName: classnames(styles.menu, menuClassName),
      onSelect,
    }}
    arrow
  >
    <Space>{children}</Space>
  </ADropdown>
)
