import React from 'react'
import { ConfigProvider, ThemeConfig } from 'antd'

export type ComponentsTheme = ThemeConfig['components'] & Pick<ThemeConfig, 'token'>

interface Props {}

export const ComponentTheme: React.FC<React.PropsWithChildren<ComponentsTheme>> = ({
  children,
  token,
  ...componentsTheme
}) => <ConfigProvider theme={{ token, components: componentsTheme }}>{children}</ConfigProvider>

export function withTheme<P extends Props>(WrappedComponent: React.FC<P>, config: ComponentsTheme) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

  const ComponentWithTheme = (props: P) => {
    return (
      <ConfigProvider theme={{ components: config }}>
        <WrappedComponent {...props} />
      </ConfigProvider>
    )
  }

  ComponentWithTheme.displayName = `withTheme(${displayName})`

  return ComponentWithTheme
}
