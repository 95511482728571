'use client'
import React, { useEffect, useState } from 'react'

interface FullscreenContextData {
  headerControlsEl: HTMLDivElement | null
}

const FullscreenContext = React.createContext<FullscreenContextData>({
  headerControlsEl: null,
})

export const useFullscreenContext = () => React.useContext(FullscreenContext)

interface FullscreenContextProviderProps {
  headerControlsElRef: React.RefObject<HTMLDivElement | null>
}

export const FullscreenContextProvider: React.FC<React.PropsWithChildren<FullscreenContextProviderProps>> = ({
  headerControlsElRef,
  children,
}) => {
  const [headerControlsEl, setHeaderControlsEl] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (headerControlsElRef.current) {
      setHeaderControlsEl(headerControlsElRef.current)
    }
  }, [headerControlsElRef])

  return <FullscreenContext.Provider value={{ headerControlsEl }}>{children}</FullscreenContext.Provider>
}
