import React from 'react'
import type { AppProps } from 'next/app'
import { CookiesProvider } from 'react-cookie'
import 'tailwindcss/tailwind.css'
import 'antd/dist/reset.css'
import '@/assets/styles/main.css'
import { AntdsTheme } from '@/components/layers/AntdsTheme'
import { Auth0Layer } from '@/components/layers/Auth0Layer'

const App: React.FC<AppProps> = ({ Component, pageProps, ...props }) => {
  return (
    <Auth0Layer>
      <CookiesProvider>
        <AntdsTheme>
          <Component {...pageProps} />
        </AntdsTheme>
      </CookiesProvider>
    </Auth0Layer>
  )
}

export default App
