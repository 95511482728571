import React, { useRef } from 'react'
import Header from '@layout/header'
import { ErrorBoundary } from '@/components/ui/molecules/ErrorBoundary'
import { PageLoadingIndicator } from '@/components/ui/molecules/PageLoadingIndicator'
import { useNextAuth } from '@/core/hooks/useNextAuth'
import { FullscreenContextProvider } from './FullscreenContext'
import * as S from './styled'

export interface FullScreenLayoutProps {
  strictHeight?: boolean
}

const FullScreenLayout: React.FC<React.PropsWithChildren<FullScreenLayoutProps>> = ({
  strictHeight = false,
  children,
}) => {
  const controlsRef = useRef<HTMLDivElement | null>(null)
  const { isLoggedIn } = useNextAuth()

  return (
    <S.RootWrapper>
      <FullscreenContextProvider headerControlsElRef={controlsRef}>
        {isLoggedIn && <Header controlsRef={controlsRef} />}
        <PageLoadingIndicator />
        <S.ContentWrapper strictHeight={strictHeight}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </S.ContentWrapper>
      </FullscreenContextProvider>
    </S.RootWrapper>
  )
}

export default FullScreenLayout
