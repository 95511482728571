import AccordionSectionArrowClose from './assets/accordion-arrow-close.svg'
import AccordionSectionArrowOpen from './assets/accordion-arrow-open.svg'
import ArrowDownIcon from './assets/arrow-down.svg'
import CampaignIcon from './assets/campaign.svg'
import Caret from './assets/caret.svg'
import ChartsIcon from './assets/charts.svg'
import CloseModal from './assets/close-modal.svg'
import CloseIcon from './assets/close.svg'
import DataVisualizationIcon from './assets/data-visualization.svg'
import DeleteCSV from './assets/delete-csv.svg'
import FileStatus from './assets/file-status.svg'
import FileIcon from './assets/file.svg'
import GridIcon from './assets/grid.svg'
import InfoIcon from './assets/info.svg'
import ListIcon from './assets/list.svg'
import LogoIcon from './assets/logo.svg'
import LogOutIcon from './assets/logout.svg'
import MapIcon from './assets/map.svg'
import PromptRunnerIcon from './assets/prompt-runner.svg'
import PropagandaIcon from './assets/propaganda.svg'
import ReportsIcon from './assets/reports.svg'
import SelectTick from './assets/select-tick.svg'
import SuspicionNewsIcon from './assets/suspicionNews.svg'
import UploadFalse from './assets/upload-false.svg'
import UploadFileIcon from './assets/upload-file.svg'
import UploadSuccess from './assets/upload-success.svg'
import UserIcon from './assets/user-icon.svg'

export {
  ListIcon,
  GridIcon,
  UserIcon,
  InfoIcon,
  LogoIcon,
  LogOutIcon,
  CampaignIcon,
  MapIcon,
  ChartsIcon,
  PropagandaIcon,
  SuspicionNewsIcon,
  ArrowDownIcon,
  UploadFileIcon,
  FileIcon,
  CloseIcon,
  DataVisualizationIcon,
  AccordionSectionArrowClose,
  AccordionSectionArrowOpen,
  SelectTick,
  Caret,
  DeleteCSV,
  FileStatus,
  CloseModal,
  UploadSuccess,
  UploadFalse,
  PromptRunnerIcon,
  ReportsIcon,
}
