import type {
  ChangePasswordParams,
  ConfirmationCodeParams,
  ConfirmationCodeResponse,
  ForceChangePasswordParams,
  RegisterParams,
  RegisterParamsResponse,
  ResendConfirmationCodeResponse,
  ResetPasswordResponse,
} from '@/server/services/CognitoUserHelper/CognitoUserHelper.types'
import { API, instance } from './instance'

export const auth = {
  signUp: (data: RegisterParams) => {
    return instance.post<RegisterParamsResponse>(API.AUTH.SIGN_UP, data)
  },

  confirmCode: (data: ConfirmationCodeParams) => {
    return instance.post<ConfirmationCodeResponse>(API.AUTH.CONFIRM_CODE, data)
  },

  getConfirmationDestinationEmail: (data: { username: string }) => {
    return instance.get<{ email: string }>(API.AUTH.CODE_DESTINATION, { params: data })
  },

  resendConfirmationCode: (data: { username: string }) => {
    return instance.post<ResendConfirmationCodeResponse>(API.AUTH.RESEND_CODE, { username: data.username })
  },

  resetPassword: (data: { username: string }) => {
    return instance.post<ResetPasswordResponse>(API.AUTH.RESET_PASSWORD, {
      username: data.username,
    })
  },

  changePassword: (data: ChangePasswordParams) => {
    return instance.post(API.AUTH.CHANGE_PASSWORD, data)
  },

  forceChangePassword: (data: ForceChangePasswordParams) => {
    return instance.post(API.AUTH.FORCE_CHANGE_PASSWORD, data)
  },
}
