import React from 'react'
import { ConfigProvider, theme } from 'antd'

const defaultColors = theme.getDesignToken({ algorithm: theme.defaultAlgorithm })

export const AntdsTheme: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ConfigProvider
    theme={{
      algorithm: theme.darkAlgorithm,
      components: {
        Select: {
          optionSelectedBg: '#2F2D34',
          colorPrimary: 'grey',
        },
        Spin: {
          colorPrimary: defaultColors.colorPrimary,
        },
      },
      token: {
        colorPrimary: '#f6425e',
        colorPrimaryHover: '#f72546',
        colorPrimaryBorder: '#f72546',
      },
    }}
  >
    {children}
  </ConfigProvider>
)
