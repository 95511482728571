import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import MainTemplate from '@/components/layout/main'
import { PAGE, PUBLIC_PAGES, PageHref, RETURN_URL_PARAM, getPagesByUserPermissions } from '@/core/constants/pages'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'
import { useNextAuth } from '@/core/hooks/useNextAuth'
import { getRedirectUrl } from '@/core/utils/url'
import { AUTH0_PATH } from './constants'

const isPublicPath = (path: PageHref | string) => PUBLIC_PAGES.includes(path as PageHref)

export const AuthLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isLoggedIn, status } = useNextAuth()
  const { user } = useCurrentUser()
  const router = useRouter()

  const authCheck = async (url: string) => {
    const path = url.split('?')[0]
    const notAuthorized = !isLoggedIn && !isPublicPath(path)
    const isWaitingPage = path === PAGE.WAITING

    if (status === 'loading') {
      return
    }

    if (notAuthorized) {
      await router.push({
        pathname: AUTH0_PATH.LOGIN,
        // pathname: PAGE.LOGIN,
        // query: isWaitingPage ? {} : { [RETURN_URL_PARAM]: router.route },
      })
      return
    }

    if (isLoggedIn) {
      const isLoginPage = path === PAGE.LOGIN
      const isGuestUser = user.isGuestUser()

      // Logged user move to redirected url from "/login" form page
      if (isLoginPage) {
        const pagePermissions = getPagesByUserPermissions(user.groups as any)
        const allowedPages = (Object.keys(pagePermissions) as PageHref[]).filter(
          (key) => !!(pagePermissions as any)[key],
        )
        const firstValidPath = allowedPages[0]

        const redirectUrl = getRedirectUrl(firstValidPath)
        const isRedirectUrlAllowed = !!allowedPages.filter((page) => redirectUrl?.startsWith(page))[0]

        let redirectPage = isRedirectUrlAllowed ? redirectUrl : firstValidPath

        if (isGuestUser) {
          redirectPage = PAGE.WAITING
        }

        await router.push({
          pathname: redirectPage,
        })
        return
      }

      // Move to "/waiting" page just registered user
      if (isGuestUser && !isLoginPage && !isWaitingPage) {
        await router.push({
          pathname: PAGE.WAITING,
        })
        return
      }
    }
  }

  useEffect(() => {
    authCheck(router.asPath)

    // const onRouteChange = (url: string) => authCheck(url)
    // router.events.on('routeChangeComplete', onRouteChange)

    // return () => {
    //   router.events.off('routeChangeComplete', onRouteChange)
    // }
  }, [isLoggedIn, status, router])

  if (!isLoggedIn && isPublicPath(router.route)) {
    return <>{children}</>
  }

  if (isLoggedIn) {
    return <>{children}</>
  }

  return <MainTemplate />
}
