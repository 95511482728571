import { Env } from '@/utils/env'

const LOG_LEVEL = process.env.LOG_LEVEL

const getTime = () => new Date().toISOString()

interface EdgeLogger {
  log(msg: string, ...data: any[]): void
  info(msg: string, ...data: any[]): void
  debug(msg: string, ...data: any[]): void
  warn(msg: string, ...data: any[]): void
  error(msg: string, ...data: any[]): void
  createLogger(args: { name: string }): EdgeLogger
}

export const createLogger = ({ name }: { name?: string } = {}): EdgeLogger => {
  const prefix = name ? `\t[${name}]` : ''
  const _logger: EdgeLogger = {
    log: (msg, ...data) => console.log(`[${getTime()}] [Log]:${prefix} ${msg}`, ...data),
    info: (msg, ...data) => console.log(`[${getTime()}] [Info]:${prefix} ${msg}`, ...data),
    debug: (msg, ...data) => console.debug(`[${getTime()}] [Debug]:${prefix} ${msg}`, ...data),
    warn: (msg, ...data) => console.warn(`[${getTime()}] [Warn]:${prefix} ${msg}`, ...data),
    error: (msg, ...data) => console.error(`[${getTime()}] [Error]:${prefix} ${msg}`, ...data),
    createLogger: ({ name: childName }) => createLogger({ name: `${name}:${childName}` }),
  }

  if (!Env.isDev()) {
    return {
      log: () => null,
      info: () => null,
      debug: () => null,
      warn: () => null,
      error: _logger.error,
      createLogger: createLogger,
    }
  }

  return _logger
}

export const logger = createLogger()
