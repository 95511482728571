import React from 'react'
import classnames from 'classnames'
import styles from './ErrorPlaceholder.module.scss'

interface ErrorPlaceholderProps {
  className?: string
  title?: string
}

export const ErrorPlaceholder: React.FC<ErrorPlaceholderProps> = ({ className, title = 'Something went wrong' }) => (
  <div className={classnames(styles.container, 'flex items-center justify-center p-4', className)}>{title}</div>
)
