import styled from 'styled-components'

interface StylesProps {
  strictHeight: boolean
}

export const RootWrapper = styled.div<Partial<StylesProps>>`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #171717;
  color: #fff;
  box-shadow: 0 2px 64px rgba(0, 0, 0, 0.35);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
`
export const ContentWrapper = styled.div<StylesProps>`
  background: radial-gradient(#28242f, #1a1a1b);
  height: ${(props) => (props?.strictHeight ? 'calc(100vh - 70px)' : 'initial')};
  min-height: ${(props) => (!props?.strictHeight ? 'calc(100vh - 70px)' : 'initial')};
`
