import React, { useEffect, useState } from 'react'
import { ApiInternal } from '@/apis/internal-api'
import { DataGrid } from '@/components/ui/molecules/DataGrid'
import styles from './VersionInfo.module.scss'

type AppVersion = Awaited<ReturnType<(typeof ApiInternal)['appVersion']>>

export const VersionInfo = () => {
  const [version, setVersion] = useState<Partial<AppVersion>>({})

  useEffect(() => {
    ApiInternal.appVersion().then((data) => {
      setVersion(data)
    })
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.title}>Version</div>
      <DataGrid
        data={[
          { label: 'Build ID', value: version.buildId },
          {
            label: 'Date',
            value: version.timestamp ? new Date(Number(version.timestamp)).toLocaleString() : undefined,
          },
          {
            label: 'Run ID',
            value: version.GITHUB_RUN_ID,
          },
          {
            label: 'Run Number',
            value: version.GITHUB_RUN_NUMBER,
          },
        ]}
      />
    </div>
  )
}
