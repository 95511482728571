import { AUTH0_PATH } from '@/components/layers/Auth0Layer/constants'
import { CognitoUserGroup } from '@/server/services/CognitoUserHelper/CognitoUserHelper.types'

export type PageHref = ValueOf<typeof PAGE>

export { AUTH0_PATH }

/** README: See `./src/pages/` for details */
export const PAGE = {
  DASHBOARD: '/' as '/',
  WAITING: '/waiting' as '/waiting',
  /** @deprecated No Login page */
  LOGIN: '/login' as '/login',
  TREND_ANALYSIS: '/trend-analysis' as '/trend-analysis',
  MAP_VIEW: '/events-geolocation' as '/events-geolocation',
  PROPAGANDA_DETECTOR: '/propaganda-detector' as '/propaganda-detector',
  SUSPICION_NEWS: '/disinfo-identification' as '/disinfo-identification',
  CHANNELS_GRAPH: '/channels-graph' as '/channels-graph',
  PROMPT_RUNNER: '/prompt-runner' as '/prompt-runner',
  REPORTS: '/reports' as '/reports',
}

export const PAGE_TITLE_MAP: Partial<Record<PageHref, string>> = {
  [PAGE.DASHBOARD]: 'Trend Analysis',
  [PAGE.TREND_ANALYSIS]: 'Trend Analysis',
  [PAGE.MAP_VIEW]: 'Events Geolocation',
  [PAGE.PROPAGANDA_DETECTOR]: 'Propaganda Detection',
  [PAGE.SUSPICION_NEWS]: 'Potential Disinfo Identification',
  [PAGE.CHANNELS_GRAPH]: 'Data Visualization',
  [PAGE.PROMPT_RUNNER]: 'Prompt Runner',
  [PAGE.REPORTS]: 'Reports',
}

// Pages do not need authorization
export const PUBLIC_PAGES: PageHref[] = [PAGE.LOGIN]

export const RETURN_URL_PARAM = 'returnUrl'

export const getPageTitle = (page: PageHref) => PAGE_TITLE_MAP[page]

const getUserPageGroup = (pagePath: PageHref) => {
  const page = pagePath.replace('/', '')
  return `${CognitoUserGroup.PageUrl}${page}` as CognitoUserGroup.PageUrl
}

// TODO: Fix once done with new auth flow
export const isPageVisible = (userGroups: CognitoUserGroup[], pageUrl?: PageHref): boolean => {
  return true

  // const isMantisUser = userGroups?.includes(CognitoUserGroup.MantisTeam)
  // if (isMantisUser) {
  //   return true
  // }
  // return pageUrl ? !!userGroups.includes(getUserPageGroup(pageUrl)) : false
}

/**
 * TODO: Fix
 * @param userGroups userGroups: CognitoUserGroup[]
 * @returns
 */
export const getPagesByUserPermissions = (userGroups: any): Omit<Record<PageHref, boolean>, '/waiting' | '/login'> => ({
  [PAGE.DASHBOARD]: isPageVisible(userGroups, PAGE.TREND_ANALYSIS),
  [PAGE.TREND_ANALYSIS]: isPageVisible(userGroups, PAGE.TREND_ANALYSIS),
  [PAGE.MAP_VIEW]: isPageVisible(userGroups, PAGE.MAP_VIEW),
  [PAGE.PROPAGANDA_DETECTOR]: isPageVisible(userGroups, PAGE.PROPAGANDA_DETECTOR),
  [PAGE.SUSPICION_NEWS]: isPageVisible(userGroups, PAGE.SUSPICION_NEWS),
  [PAGE.CHANNELS_GRAPH]: isPageVisible(userGroups, PAGE.CHANNELS_GRAPH),
  [PAGE.PROMPT_RUNNER]: isPageVisible(userGroups, PAGE.PROMPT_RUNNER),
  [PAGE.REPORTS]: isPageVisible(userGroups, PAGE.REPORTS),
  // [PAGE.WAITING]: isPageVisible(userGroups) || !userGroups.length,
})
