import React from 'react'
import { useRouter } from 'next/router'
import { Icon } from '@/components/ui/atoms/icon'
import { PAGE } from '@/core/constants/pages'
import { PageNavigator } from './PageNavigator'
import { ProfileButton } from './Profile'
import * as S from './styled'

interface HeaderProps {
  controlsRef?: React.Ref<HTMLDivElement>
}

const Header: React.FC<HeaderProps> = ({ controlsRef }) => {
  const router = useRouter()

  if (router.pathname === PAGE.LOGIN) {
    return null
  }

  return (
    <S.Wrapper>
      <S.LogoWrapper href={PAGE.DASHBOARD}>
        <Icon name="LogoIcon" />
      </S.LogoWrapper>
      <S.ControlsWrapper>{controlsRef && <div id="header-controls" ref={controlsRef} />}</S.ControlsWrapper>
      <PageNavigator />
      <ProfileButton />
    </S.Wrapper>
  )
}

export default Header
