import React from 'react'
import Head from 'next/head'
import FullScreenLayout, { FullScreenLayoutProps } from '../full-screen'

interface MainTemplateProps extends FullScreenLayoutProps {}

export const MainTemplate: React.FC<React.PropsWithChildren<MainTemplateProps>> = ({ strictHeight, children }) => {
  return (
    <>
      <Head>
        <title>Mantis Analytics</title>
        <meta name="robots" content="noindex,nofollow" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap"
          rel="stylesheet"
        />
        <link href="https://cdn.unfolded.ai/statics/fonts/unfolded-fonts.css" rel="stylesheet" />
      </Head>
      <FullScreenLayout strictHeight={strictHeight}>{children}</FullScreenLayout>
    </>
  )
}

export default MainTemplate
