import type {
  ServiceStatusResponse as NewsStatusResponse,
  NewsCheckTextPayload,
  NewsCheckTextResponse,
  NewsCheckReadFilePayload,
  NewsCheckReadFileResponse,
} from '@/pages/api/susp-news/types'

import { API, instance } from './instance'

export const news = {
  status: () => instance.get<NewsStatusResponse>(API.NEWS.STATUS),
  checkText: (content: string) =>
    instance.post<NewsCheckTextResponse>(API.NEWS.CHECK_TEXT, <NewsCheckTextPayload>{ content }),
  
  readFile: (data: { file: File }) => {
    const formData: NewsCheckReadFilePayload = new FormData()
    formData.append('file', data.file)
    return instance.post<NewsCheckReadFileResponse>(API.NEWS.READ_FILE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
