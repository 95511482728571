import { PAGE, RETURN_URL_PARAM } from '../constants/pages'

export const getRedirectUrl = (returnUrl?: string): string | undefined => {
  if (!returnUrl) {
    const params = new URLSearchParams(location.search)
    const returnUrlParam = params.get(RETURN_URL_PARAM)
    returnUrl = Array.isArray(returnUrlParam) ? returnUrlParam[0] : returnUrlParam
  }
  const redirectUrl = (returnUrl && decodeURIComponent(returnUrl)) || PAGE.DASHBOARD
  return redirectUrl
}
