import Link from 'next/link'
import styled from 'styled-components'
import { Button as AButton, Dropdown as ADropdown } from 'antd'

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  padding-left: 56px;
  height: 70px;
  background: rgba(255, 255, 255, 0.03);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
`

export const LogoWrapper = styled(Link)`
  && {
    flex: 1 1 auto;
    height: 100%;
    max-width: 250px;
    padding: 21px 0;

    svg {
      width: 196px;
      fill: #fff;
    }
  }
`
export const ControlsWrapper = styled.div`
  flex: 3 1 auto;
  display: flex;
  align-items: center;

  && {
    #header-controls {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`

export const LogoutButton = styled(AButton)`
  && {
    flex: 0 1 auto;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: white;
    align-self: center;
    margin: 0 9px;

    path {
      fill: #f6425e;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      margin-right: 12px;
    }

    &:hover {
      path {
        fill: #4096ff;
      }
    }
  }
`

export const LoginButton = styled(AButton)`
  && {
    flex: 0 1 auto;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: white;
    align-self: center;
    margin: 0 9px;

    &:hover {
      path {
        fill: #4096ff;
      }
    }
  }
`

export const Dropdown = styled(ADropdown)`
  && > div {
    background: black;
  }
`
