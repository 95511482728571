import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Progress } from 'antd'
import styles from './PageLoadingIndicator.module.scss'

export const PageLoadingIndicator = () => {
  const router = useRouter()
  const [loading, setIsLoading] = useState(false)
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    const start = () => {
      setPercent(0)
      setIsLoading(true)
    }
    const complete = () => {
      setPercent(100)
      setIsLoading(false)
    }
    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeComplete', complete)
    return () => {
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeComplete', complete)
    }
  }, [])

  useEffect(() => {
    let id: NodeJS.Timer
    if (loading) {
      id = setInterval(() => setPercent((v) => Math.max(v + 5, 100)), 400)
    }
    return () => clearInterval(Number(id))
  }, [loading])

  if (!loading) {
    return null
  }

  return (
    <Progress
      className={styles.wrapper}
      percent={percent}
      size={[percent, 4]}
      strokeLinecap="butt"
      strokeColor={{ '0%': '#353339', '100%': '#2F2D33' }}
      showInfo={false}
    />
  )
}
