import React from 'react'
import classNames from 'classnames'
import styles from './DataGrid.module.scss'

interface RowProps {
  label: string
  value?: string | null
  showEmpty?: boolean
}

export interface DataGridProps {
  className?: string
  data: Pick<RowProps, 'label' | 'value'>[]
  showEmpty?: RowProps['showEmpty']
}

const Row: React.FC<RowProps> = ({ label, value, showEmpty }) => {
  if (!value && !showEmpty) {
    return null
  }

  return (
    <>
      <div>{label}</div>
      <div>{value}</div>
    </>
  )
}

export const DataGrid: React.FC<DataGridProps> = ({ className, data, showEmpty }) => {
  return (
    <div className={classNames(styles.container, className)}>
      {data.map((item) => (
        <Row key={item.label} label={item.label} value={item.value} showEmpty={showEmpty} />
      ))}
    </div>
  )
}
