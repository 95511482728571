import { ReportItem } from '@/pages/api/reports/types'
import { API, instance } from './instance'

export const reports = {
  getReports: () => instance.get<ReportItem[]>(API.REPORTS.LIST),

  getSignedUrl: (payload: Pick<ReportItem, 'report_url'>) =>
    instance.post<{ signedUrl: string }>(API.REPORTS.DOWNLOAD_URL, payload),

  getReportThumbnail: (payload: Pick<ReportItem, 'report_url'>) =>
    instance.post<{ imageSrc: string }>(API.REPORTS.THUMBNAIL, payload),
}
