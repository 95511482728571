import React, { useState } from 'react'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import { Dropdown, DropdownProps } from '@/components/ui/atoms/dropdown'
import { Icon } from '@/components/ui/atoms/icon'
import { AUTH0_PATH, PAGE } from '@/core/constants/pages'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'
import { useNextAuth } from '@/core/hooks/useNextAuth'
import styles from './ProfileButton.module.scss'
import { VersionInfo } from './VersionInfo'

const getItems = ({ username, logout }: { username: string; logout(): void }): DropdownProps['items'] => [
  {
    className: styles.header,
    key: 'username',
    type: 'group',
    label: (
      <span className={styles.headerLabel}>
        <Icon name="UserIcon" />
        <span>{username}</span>
      </span>
    ),
  },
  // {
  //   key: 'about',
  //   label: 'About',
  //   icon: <Icon name="InfoIcon" />,
  //   onClick: () => console.log('about'),
  // },
  {
    key: 'logout',
    label: 'Log out',
    icon: <Icon name="LogOutIcon" />,
    onClick: logout,
  },
  {
    className: styles.version,
    key: 'info',
    label: <VersionInfo />,
    disabled: true,
  },
]

export const ProfileButton = () => {
  const [isOpened, setOpened] = useState(false)
  const { isLoggedIn, logout } = useNextAuth()
  const { user } = useCurrentUser()
  const router = useRouter()

  const items = getItems({ username: user.name, logout })

  if (!isLoggedIn) {
    const onLogin = () => router.push({ pathname: AUTH0_PATH.LOGIN })
    return <div onClick={onLogin}>Login</div>
  }

  return (
    <Dropdown
      className={styles.container}
      menuClassName={styles.profileMenu}
      overlayClassName={styles.overlay}
      onOpenChange={(open) => setOpened(open)}
      items={items}
      trigger="hover"
      placement="bottomRight"
      selectable
      destroyPopupOnHide
    >
      <Icon className={classnames(styles.icon, { [styles.opened]: isOpened })} name="UserIcon" fillColor />
    </Dropdown>
  )
}
