import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import { find } from 'lodash'
import { Dropdown, DropdownProps } from '@/components/ui/atoms/dropdown'
import { Icon } from '@/components/ui/atoms/icon'
import { PAGE, getPageTitle, getPagesByUserPermissions } from '@/core/constants/pages'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'
import { type CurrentUserModel } from '@/core/models/CurrentUserModel'
import styles from './PageNavigator.module.scss'

const pageNavItems = [
  {
    label: getPageTitle(PAGE.TREND_ANALYSIS),
    icon: <Icon name="ChartsIcon" />,
    href: PAGE.TREND_ANALYSIS,
  },
  // {
  //   label: getPageTitle(PAGE.MAP_VIEW),
  //   icon: <Icon name="MapIcon" />,
  //   href: PAGE.MAP_VIEW,
  // },
  {
    label: getPageTitle(PAGE.PROPAGANDA_DETECTOR),
    icon: <Icon name="PropagandaIcon" />,
    href: PAGE.PROPAGANDA_DETECTOR,
  },
  {
    label: getPageTitle(PAGE.SUSPICION_NEWS),
    icon: <Icon name="SuspicionNewsIcon" />,
    href: PAGE.SUSPICION_NEWS,
    disabled: false,
  },
  {
    label: getPageTitle(PAGE.REPORTS),
    icon: <Icon name="ReportsIcon" />,
    href: PAGE.REPORTS,
    disabled: false,
  },
  // {
  //   label: getPageTitle(PAGE.PROMPT_RUNNER),
  //   icon: <Icon name="PromptRunnerIcon" />,
  //   href: PAGE.PROMPT_RUNNER,
  //   disabled: false,
  // },
  {
    label: getPageTitle(PAGE.CHANNELS_GRAPH),
    icon: <Icon name="DataVisualizationIcon" />,
    href: PAGE.CHANNELS_GRAPH,
    disabled: false,
  },
]

const getItems = (user: CurrentUserModel): DropdownProps['items'] => {
  const pagePermissions = getPagesByUserPermissions(user.groups)
  return pageNavItems
    .filter((item) => pagePermissions[item.href])
    .map((item) => ({
      key: item.href,
      disabled: item.disabled,
      icon: item.icon,
      label: <Link href={item.href}>{item.label}</Link>,
    }))
}

export const PageNavigator: React.FC = () => {
  const router = useRouter()
  const { user } = useCurrentUser()
  const [isOpen, setIsOpen] = useState(false)

  const { label, icon } = find<any>(pageNavItems, { href: router.route }) || { label: 'Navigator' }

  if (user.isGuestUser()) {
    return null
  }

  return (
    <Dropdown
      className={styles.container}
      menuClassName={styles.menu}
      items={getItems(user)}
      trigger="hover"
      placement="bottomCenter"
      selectable
      defaultSelectedKeys={[router.route]}
      onOpenChange={setIsOpen}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon && React.cloneElement(icon, { size: 20 })}
        <span style={{ paddingLeft: 8 }}>{label}</span>
      </div>
      <Icon className={classnames(styles.arrow, { [styles.arrowOpen]: isOpen })} name="ArrowDownIcon" size={20} />
    </Dropdown>
  )
}
